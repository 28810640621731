export const PacagesData = [
    {
        id: 1,
        title: "INTERNET",
        button: "INTERNET",
        dataLimit: "300 Mbps",
        price: "49"
    },
    {
        id: 2,
        title: "INTERNET ULTRA",
        button: "ULTRA",
        dataLimit: "500 Mbps",
        price: "69"
    },
    {
        id: 3,
        title: "INTERNET GIG",
        button: "GIG",
        dataLimit: "1 Gbps",
        price: "89"
    },
]
