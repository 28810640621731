import styled from "styled-components";

export const MainContainer = styled.div`
max-width: 1440px;
width: 100%;
margin : 0 auto;
/* display:flex; */
/* justify-content: center; */

`
