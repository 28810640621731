import React, { useEffect, useState } from 'react'
import { MainWrapper, FirstWrapperDiv, Title1, SubTitle, ImgWrapper, Button, SecondWrapperDiv, IconFlex } from "./Pacage.styled"
import { SecondFlex } from '../Section2/Section2.styled'
import Line from "../../assets/images/mobile/line.webp"
import laptop from "../../assets/images/mobile/laptop.webp"
import wifi from "../../assets/images/mobile/wifi.webp"
import mobile from "../../assets/images/mobile/mobile.png"
import phone from "../../assets/images/mobile/phone.webp"


import { InnerFlex, MainFlex } from '../Section1/Section1.styled'
const Pacage = ({ itemId, title, price, button, dataLimit }) => {

    const [tfn, setTfn] = useState(null)
    const handleClick = () => {
        // Check if the gtag function is defined
        if (typeof window.gtag === 'function') {
          const phoneNumber = tfn || "1-833-923-0269"; 
       
          window.gtag('event', 'conversion', {
            'send_to': 'AW-606595014/9F__CNHTn94DEMbPn6EC',
            //  'event_callback': 'callback',
            'phone_number': phoneNumber,
          });
          
        } else {
          console.error('Google Analytics gtag function is not defined.');
        }
      
      };
    const  setUrl = (url) => {
         let searchParams = new URLSearchParams(window.location.search);
         searchParams.set("tfn", url);
         let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
         window.history.pushState({ path: newurl }, "", newurl);
         window.scrollTo(0, 0)
         setTfn(url)
     }
 
    const  getTfn = (tfn) => {
         let temp = ""
         for(let i = 0 ; i < tfn.length ; i++){
             if(tfn[i] !== "-"){
                 temp = temp + tfn[i]
             }
         }
         return "tel:+"+temp
     }
 
    const getTfnFormat = (tfn) => {
         let _tfn = ""
         // if(tfn.length === 14){
         tfn = tfn.split('-')
         _tfn = "("+tfn[1]+") "+ tfn[2] +"-" + tfn[3]
         // }
         return _tfn
     }
     const urlParams = new URLSearchParams(window.location.search);
     let tfn1 = urlParams.get("tfn");


    return (
        <MainWrapper>
            <FirstWrapperDiv>
                <Title1>{title}</Title1>
                <SubTitle>+ WIFI + MOBILE</SubTitle>
                <ImgWrapper>
                    <img src={Line} alt="whiteLine" />
                </ImgWrapper>

                <MainFlex style={{ justifyContent: "center" }}>
                    <p>$</p>
                    <p>{price}</p>
                    <InnerFlex>
                        <p><span style={{fontFamily:'SpectrumSans Bold'}}>99</span><span style={{fontFamily:'SpectrumSans Regular', fontSize:'15px' }}>/mo</span></p>
                        <p><span style={{fontFamily:'SpectrumSans Regular'}}>for 12 mos</span></p>
                    <p><span style={{fontFamily:'SpectrumSans Regular'}}>when bundled</span></p>
                    </InnerFlex>
                </MainFlex>
                <Button>{button}</Button>
            </FirstWrapperDiv>
            <SecondWrapperDiv>
                <IconFlex>
                    <div>
                        <img src={laptop} alt="icon" />
                    </div>
                    <p>Up to {dataLimit}</p>

                </IconFlex>
                <IconFlex>
                    <div>
                        <img src={wifi} alt="icon" />

                    </div>
                    <p>Advanced WiFi</p>
                </IconFlex>
                <IconFlex>
                    <div>
                        <img src={mobile} alt="icon" />
                    </div>
                    <p>Unlimited Mobile Line</p>
                </IconFlex>
                
            </SecondWrapperDiv>
            <SecondFlex>
                        <div><img src={phone} alt="tickIcon" /> </div>
                        <a className="ph" onClick={handleClick}  href={tfn1 === null ? "tel:+18339230269":getTfn(tfn1)}>{tfn1 === null? getTfnFormat("1-833-923-0269") :getTfnFormat(tfn1)} </a>
                    </SecondFlex>
        </MainWrapper>
    )
}

export default Pacage;
