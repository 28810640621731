import React from 'react'
import Pacage from './Pacage'
import { MainWrapper, MainTitle, Description, PacagesFlex } from "./Section4.styled"
import { PacagesData } from "./PacagesData"

const Section4 = () => {
    return (
        <MainWrapper>
            <MainTitle>Save with Spectrum One</MainTitle>
            <Description>Experience seamless connectivity across all of your devices from America’s leading Internet </Description>
            <PacagesFlex>
                {PacagesData.map((item) => {
                    return <Pacage title={item.title} price={item.price} button={item.button} dataLimit={item.dataLimit} key={item.id} />
                })}


            </PacagesFlex>

        </MainWrapper>
    )
}

export default Section4;
