import Home from "../containers/Home/Home";
 import Privacy from "../containers/Privacy/Privacy"

export const routes = [
    {
        path: "/",
        component: Home
    },
    {
        path: "/privacy",
        component: Privacy
    },
]