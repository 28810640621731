import React, { useState, useEffect } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { withRouter } from "react-router";
import { MainContainer } from "./Layout.styled";
// sidebar-collapse
const Layout = (props) => {
    let url = props.location.pathname

    return (
        <>
        <MainContainer>
            <Header />
            </MainContainer>
            {props.children}
       
      
             <Footer />
             </>
    )
};

export default withRouter(Layout);