import styled from "styled-components";

export const FooterWrapper = styled.div`
width: 100%;
padding: 12% 4%;
@media (min-width: 769px) {
                padding: 4% 4%;
               }
               @media (min-width: 576px) and (max-width: 768px)  {
                padding: 6% 4%;
                }
`
export const FooterImgWrapper = styled.div`
float : right;
width:100%;
display:flex;
height:100%;
align-items:center;
@media (min-width: 576px) and (max-width: 768px)  {
  width: 80%;
  }
@media (min-width: 769px) {
  width:70%;
 }
img{
  width:40%;
}
`

export const LeftText = styled.div`
.linkclas{
  text-decoration: none; !important;
  cursor: pointer;
  color:#333333;
}

font-size: 16px;
line-height: 24px;
color: #333333;
font-family: "SpectrumSans Medium";
margin-top: 60%;

@media (min-width: 576px) and (max-width: 768px)  {
 width: 50%;
 margin-top: 0%;
 }
               @media (min-width: 769px) {
                 width: 50%;
                 margin-top: 0%;

               }
`
export const FooterBtn = styled.div`
width: 174px;
height: 53px;
background-color: #efefef;
font-size: 10px;
color: #ff00ff;
font-weight: 700;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
 @media (min-width: 576px) and (max-width: 768px)  {
 width: 270px;
height: 86px;
margin: 0 0 0 auto;
 }
               @media (min-width: 769px) {
                width: 270px;
height: 86px;
margin: 0 0 0 auto;
               }
`
export const DetailWrapper = styled.div`

margin-top: 2.5rem;
p{
        font-size: 12px;
line-height: 18px;
color: #333333;
font-weight: 500;
font-family: "SpectrumSans Medium";
}
a{
    color:#333333 !important;
    text-decoration: underline  !important;
    cursor: pointer;
}
`
export const LittleTitle = styled.p`
    font-size: 12px;
line-height: 18px;
color: #333333;
font-weight: 500;
margin-bottom: 0 !important;
`

