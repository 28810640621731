import React, { useEffect, useState } from 'react'

import { ImgWrapper, MainWrapper, BlueTitle, Title1, MainFlex, ContentWrapper, InnerFlex, SecondFlex, LiteText, MainContainer } from '../Section2/Section2.styled'
import sale from "../../assets/images/mobile/Save.webp"
import Tick from "../../assets/images/mobile/check.webp"
import phone from "../../assets/images/mobile/phone.webp"
import DesktopSale from "../../assets/images/desktop/sale.webp"

const Section2 = () => {

    const [tfn, setTfn] = useState(null)
    const handleClick = () => {
        // Check if the gtag function is defined
        if (typeof window.gtag === 'function') {
          const phoneNumber = tfn || "1-833-923-0269"; 
       
          window.gtag('event', 'conversion', {
            'send_to': 'AW-606595014/9F__CNHTn94DEMbPn6EC',
            //  'event_callback': 'callback',
            'phone_number': phoneNumber,
          });
          
        } else {
          console.error('Google Analytics gtag function is not defined.');
        }
      
      };
    
    const  getTfn = (tfn) => {
         let temp = ""
         for(let i = 0 ; i < tfn.length ; i++){
             if(tfn[i] !== "-"){
                 temp = temp + tfn[i]
             }
         }
         return "tel:+"+temp
     }
 
    const getTfnFormat = (tfn) => {
         let _tfn = ""
         // if(tfn.length === 14){
         tfn = tfn.split('-')
         _tfn = "("+tfn[1]+") "+ tfn[2] +"-" + tfn[3]
         // }
         return _tfn
     }
     const urlParams = new URLSearchParams(window.location.search);
     let tfn1 = urlParams.get("tfn");


    const [isDesktop, setIsDesktop] = useState(
        window.innerWidth > 768 ? true : false
    );
    const [isTab, setIsTab] = useState(
        (window.innerWidth >= 576 && window.innerWidth <= 768) ? true : false
    );
    const updateDimensions = () => {
        setIsDesktop(window.innerWidth > 768 ? true : false);
        setIsTab((window.innerWidth >= 576 && window.innerWidth <= 768) ? true : false);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [isDesktop]);
    return (
        <MainContainer>
            <MainWrapper>
                <ImgWrapper>
                    <img src={isDesktop ? DesktopSale : sale} alt="salePic" />
                </ImgWrapper>
                <ContentWrapper>
                    <BlueTitle>SPECTRUM MOBILE™</BlueTitle>
                    <Title1>A Better Way to Mobile Is Here</Title1>
                    <MainFlex>
                        <InnerFlex>
                            <div><img src={Tick} alt="tickIcon" /> </div>
                            <p>Mix and match data plans with Unlimited or By the Gig options</p>
                        </InnerFlex>
                        <InnerFlex>
                            <div><img src={Tick} alt="tickIcon" /> </div>
                            <p>Most reliable mobile service coast to coast</p>
                        </InnerFlex>
                        <InnerFlex>
                            <div><img src={Tick} alt="tickIcon" /> </div>
                            <p>Unlimited nationwide talk and text</p>
                        </InnerFlex>
                        <InnerFlex>
                            <div><img src={Tick} alt="tickIcon" /> </div>
                            <p>No contracts, added taxes or hidden fees</p>
                        </InnerFlex>
                    </MainFlex>
                    <SecondFlex style={{justifyContent:"flex-start"}}>
                        <div><img src={phone} alt="tickIcon" /> </div>
                        <a className="ph" onClick={handleClick} href={tfn1 === null ? "tel:+18339230269":getTfn(tfn1)}>{tfn1 === null? getTfnFormat("1-833-923-0269") :getTfnFormat(tfn1)} </a>
                    </SecondFlex>
                    <LiteText>^^Savings based on single line comparison of unlimited plans among major nat’l carriers as of 08/2022: prepaid excl: data usage limits vary by carrier.</LiteText>

                </ContentWrapper>
            </MainWrapper>
        </MainContainer>
    )
}

export default Section2;
