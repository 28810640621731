import React, { useState, useEffect } from 'react';
// import { Container, Nav, Navbar, NavDropdown, Row, Col } from "react-bootstrap";
import { withRouter, Link, Redirect } from "react-router-dom";
import Logo from "../../assets/images/mobile/logo-new.png"
import { HeaderWrapper, LogoWrapper, ContactDetailWrapper } from "./Header.styled"
import TabLogo from "../../assets/images/tab/logo-new.png"
import DesktopLogo from "../../assets/images/desktop/logo-new.png"

// sidebar-collapse
const Header = (props) => {

    const [tfn, setTfn] = useState(null);
 
    
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      let tfnFromUrl = urlParams.get("tfn");
  
      if (tfnFromUrl) {
        setTfn(tfnFromUrl);
      }
    }, []);
  
    const setUrl = (url) => {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("tfn", url);
      let newurl =
        window.location.protocol +
        window.location.host +
        window.location.pathname +
        "?" +
        searchParams.toString();
      window.history.pushState({ path: newurl }, "", newurl);
      window.scrollTo(0, 0);
      setTfn(url);
    };
  
    const getTfn = (tfn) => {
      let temp = "";
      for (let i = 0; i < tfn.length; i++) {
        if (tfn[i] !== "-") {
          temp = temp + tfn[i];
        }
      }
      return "tel:+" + temp;
    };
  
    const getTfnFormat = (tfn) => {
      let _tfn = "";
      tfn = tfn.split("-");
      _tfn = "1" + "-" + tfn[1] + "-" + tfn[2] + "-" + tfn[3];
      return _tfn;
    };
    const handleClick = () => {
        // Check if the gtag function is defined
        if (typeof window.gtag === 'function') {
          const phoneNumber = tfn || "1-833-923-0269"; 
       
          window.gtag('event', 'conversion', {
            'send_to': 'AW-606595014/9F__CNHTn94DEMbPn6EC',
            //  'event_callback': 'callback',
            'phone_number': phoneNumber,
          });
          
        } else {
          console.error('Google Analytics gtag function is not defined.');
        }
      
      };
    const numberToShow = tfn || "1-833-923-0269";

    const [isDesktop, setIsDesktop] = useState(
        window.innerWidth > 768 ? true : false
    );
    const [isTab, setIsTab] = useState(
        (window.innerWidth >= 576 && window.innerWidth <= 768) ? true : false
    );
    const updateDimensions = () => {
        setIsDesktop(window.innerWidth > 768 ? true : false);
        setIsTab((window.innerWidth >= 576 && window.innerWidth <= 768) ? true : false);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [isDesktop]);

    return (
        <>
            <HeaderWrapper>
                <div className="row" style={{
                    height: "100%",
                    alignItems: "center"
                }}>
                    <div className="col-5">
                        <LogoWrapper>
                        <a href="/">    <img src={!isTab && !isDesktop ? Logo : isDesktop ? DesktopLogo : TabLogo} alt="Linking Flux LLC | Authorized Reseller of TV, Internet & Phone Services" /></a>
                        </LogoWrapper>
                    </div>
                    {/* <div className="col-2"></div> */}
                    <div className="col-7" style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <ContactDetailWrapper>
                            <p className="claim">Call To Claim This Offer:</p>
                            {/* <p className="ph" >1-888-123-4567</p> */}
                            <a className="ph" onClick={handleClick} href={tfn === null ? "tel:+18339230269":getTfn(tfn)}>{tfn === null? getTfnFormat("1-833-923-0269") :getTfnFormat(tfn)} </a>
                            <div>

                            </div>
                        </ContactDetailWrapper>
                    </div>

                </div>
            </HeaderWrapper>
        </>

    )

};

export default withRouter(Header);