import styled from "styled-components";
import bg from "../../assets/images/mobile/bg.webp"
export const MainWrapper = styled.div`
    width: 100%;
    // background-image:url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2% 4%;
     @media (min-width: 576px) and (max-width: 768px)  {
        display: flex;
     }
         @media (min-width: 769px) {
        display: flex;
               }
`
export const ImgWrapper = styled.div`
    width :  100%;
    height:  291px;
    margin: 0 auto;
    img{
        width: 100%;
        height:-webkit-fill-available;
    }
 
    @media (min-width: 576px) and (max-width: 768px)  {
        order:2;
        display: flex;
        justify-content: center;
        width: 50%;
        min-width:50%;
align-self:flex-end;
     }
         @media (min-width: 769px) {
 order:2;
        display: flex;
        justify-content: center;
        width: 50%;
        min-width:50%;
align-self:flex-end;
height: auto;
img{
    width: 54%;
    height:-webkit-fill-available;
}
               }

`
export const DetailWrapper = styled.div`
@media (min-width: 576px) and (max-width: 768px)  {
        order:1;
        width: 50%;  
     }
         @media (min-width: 769px) {
 order:1;
        width: 50%; 

               }
`

export const Textwrapper = styled.div`
@media (min-width: 576px) {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    margin: auto 0;
        width: 50%;  
     }

`

export const Title1 = styled.p`
        font-size: 42px;
line-height: 50px;
color: #ffffff;
font-family: "SpectrumSans Medium";


`
export const Ptag = styled.p`
        font-size: 16px;
line-height: 22px;
color: #ffffff;
font-family: "SpectrumSans Light";

`
export const Title2 = styled.p`
        font-size: 18px;
color: #ffffff;
margin-bottom: 1px;
font-family: "SpectrumSans Regular";
`
export const MainFlex = styled.div`
        width: 100%;
        display: flex;
         p:nth-of-type(1){
       font-size: 32px;
color:${props => props.color ? props.color : "#ffffff"};
margin-bottom: 0!important;
    }
p:nth-of-type(2){
    font-size: 92px;
    line-height: 92px;
    color:${props => props.color ? props.color : "#ffffff"};
    font-family: "SpectrumSans Bold"; 
    margin-bottom :0 !important;
}
`
export const InnerFlex = styled.div`
display: flex;
flex-direction: column;
line-height: 1;
margin-top: 0%;
p:nth-of-type(1){
    font-size: 32px;
color:${props => props.color ? props.color : "#ffffff"};
    // font-weight: bold;
    margin-bottom: 0!important;
        span{
    }
}
p:nth-of-type(2){
    font-size: 12px;
    line-height: 15px;
color:${props => props.color ? props.color : "#ffffff"};
    margin-bottom: 0!important;
    font-family: "SpectrumSans Bold";  
}
p:nth-of-type(3){
    font-size: 12px;
    line-height: 15px;
color:${props => props.color ? props.color : "#ffffff"};
    margin-bottom: 0!important;
}

//for tablet
@media(min-width: 576px) and(max-width: 768) {


}




`

