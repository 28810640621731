import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./style.css";
import { IoCall } from "react-icons/io5";

const Section6 = ({ tfn, getTfnFormat, getTfn, handleClick }) => {




    return (
      <>
        <div className="section2_main">
          <div className="container">
            <div className="sec2_btn">
              <a href="">
                <h3 className="sec2_h3">Cable Internet Best Deals</h3>
                <div className="phone_divv my-2">
                <a className="sec2_b" onClick={handleClick} href={tfn === null ? "tel:+18339230269": getTfn(tfn)}><span style={{display:"flex", alignItems:"center", gap:"0.5rem"}}><IoCall size={20} />  CALL NOW {tfn === null? getTfnFormat("1-833-923-0269") : getTfnFormat(tfn)}</span></a>
                </div>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }



export default withRouter(Section6);
