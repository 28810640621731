
import { MainFlex, InnerFlex } from '../Section1/Section1.styled'
import { BlueTitle, SecondFlexother, Title1 } from './Section2.styled'
import { MainWrapper, ImgContainer, TopFlex, InerFlex, DetailWrapper, GreyText, SmallTitle, BoldText, LiteText, ImgWrapper } from './Section3.styled'
import img from "../../assets/images/mobile/phone-image.webp"
import phone from "../../assets/images/mobile/phone.webp"
import { LazyLoadImage } from "react-lazy-load-image-component";
import Carousel from 'react-bootstrap/Carousel';
import silder0 from "../../assets/images/cro/1.jpg"
import silder1 from "../../assets/images/cro/3.jpg"
import React, { useEffect, useState } from 'react'
import Section4 from '../Section4/Section4'
import Banner from '../../assets/images/Home-bannerr11.jpg'
import "./style2.css"
const Section3 = () => {
    const [isDesktop, setIsDesktop] = useState(
        window.innerWidth > 768 ? true : false
    );
    const [isTab, setIsTab] = useState(
        (window.innerWidth >= 576 && window.innerWidth <= 768) ? true : false
    );
    const updateDimensions = () => {
        setIsDesktop(window.innerWidth > 768 ? true : false);
        setIsTab((window.innerWidth >= 576 && window.innerWidth <= 768) ? true : false);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [isDesktop]);
    const [tfn, setTfn] = useState(null)
    const handleClick = () => {
        // Check if the gtag function is defined
        if (typeof window.gtag === 'function') {
          const phoneNumber = tfn || "1-833-923-0269"; 
       
          window.gtag('event', 'conversion', {
            'send_to': 'AW-606595014/9F__CNHTn94DEMbPn6EC',
            //  'event_callback': 'callback',
            'phone_number': phoneNumber,
          });
          
        } else {
          console.error('Google Analytics gtag function is not defined.');
        }
      
      };
    const  setUrl = (url) => {
         let searchParams = new URLSearchParams(window.location.search);
         searchParams.set("tfn", url);
         let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
         window.history.pushState({ path: newurl }, "", newurl);
         window.scrollTo(0, 0)
         setTfn(url)
     }
 
    const  getTfn = (tfn) => {
         let temp = ""
         for(let i = 0 ; i < tfn.length ; i++){
             if(tfn[i] !== "-"){
                 temp = temp + tfn[i]
             }
         }
         return "tel:+"+temp
     }
 
    const getTfnFormat = (tfn) => {
         let _tfn = ""
         // if(tfn.length === 14){
         tfn = tfn.split('-')
         _tfn = "("+tfn[1]+") "+ tfn[2] +"-" + tfn[3]
         // }
         return _tfn
     }
     const urlParams = new URLSearchParams(window.location.search);
     let tfn1 = urlParams.get("tfn");
    return (
        // <MainWrapper>
        //     <ImgWrapper>
        //         {/* <ImgContainer>
        //             <TopFlex>
        //                 <div>More</div>
        //                 <InerFlex>
        //                     <div>
        //                         <p>Lines</p>
        //                         <p>More</p>
        //                     </div>
        //                     <p>Savings</p>
        //                 </InerFlex>
        //             </TopFlex>
        //         </ImgContainer> */}
        //            <LazyLoadImage  effect="blur" src={img} alt="detailImg" />
        //     </ImgWrapper>
        //     <DetailWrapper>
        //         <br />
        //         <BlueTitle>OUR BEST DEAL EVER</BlueTitle>
        //         <Title1>Spectrum Mobile Unlimited Data Plans</Title1>
        //         <GreyText>Enjoy special savings on Unlimited data with plans starting at just $29.99/mo per line. Use all the data you want with nationwide 5G included.</GreyText>
        //         <SmallTitle style={{fontFamily:'SpectrumSans Regular'}}>From</SmallTitle>
        //         <MainFlex color="black">
        //             <p>$</p>
        //             <p>29</p>
        //             <InnerFlex color="black">
        //                 <p><span>99</span><span style={{fontSize:'18px'}}>/mo</span></p>
        //                 <p></p>
        //                 <p>per line</p>
        //             </InnerFlex>
        //         </MainFlex>
        //         <SecondFlex style={{justifyContent:"flex-start" ,
        //     paddingBottom:"0",
        //     }}>
        //                 <div><img src={phone} alt="tickIcon" /> </div>
        //                 <a className="ph" href={tfn1 === null ? "tel:+18339230269":getTfn(tfn1)}>{tfn1 === null? getTfnFormat("1-833-923-0269") :getTfnFormat(tfn1)} </a>
        //             </SecondFlex>
        //         <BoldText>Restrictions Apply</BoldText>
        //         <LiteText>Spectrum Internet required.</LiteText>
        //     </DetailWrapper>
        // </MainWrapper>
        <>    
        <Carousel>
        <Carousel.Item interval={2000}>
        <div className='carousel-imggg'>  
        <MainWrapper>
            
           
                {/* <ImgContainer>
                    <TopFlex>
                        <div>More</div>
                        <InerFlex>
                            <div>
                                <p>Lines</p>
                                <p>More</p>
                            </div>
                            <p>Savings</p>
                        </InerFlex>
                    </TopFlex>

                </ImgContainer> */}
                {isDesktop ?(
                    <div className='imgCover'>
                    <img src={img} alt="img" />
                    </div>
                ):(
                  <></>
                )}
              
                   {/* <LazyLoadImage  effect="blur" src={img} alt="detailImg" /> */}
           
            <DetailWrapper>
                <br />
                <BlueTitle>OUR BEST DEAL EVER</BlueTitle>
                <Title1>Spectrum Mobile Unlimited Data Plans</Title1>
                <GreyText>Enjoy special savings on Unlimited data with plans starting at just $29.99/mo per line. Use all the data you want with nationwide 5G included.</GreyText>
                <SmallTitle >From</SmallTitle>
                <MainFlex color="black">
                    <p>$</p>
                    <p>29</p>
                    <InnerFlex color="black">
                        <p><span>99</span><span style={{fontSize:'18px'}}>/mo</span></p>
                        <p></p>
                        <p>per line</p>
                    </InnerFlex>
                </MainFlex>
                <SecondFlexother style={{justifyContent:"flex-start" ,
            paddingBottom:"0", backgroundColor:"black", padding:"6px", borderRadius:"38px", width:"200px", textAlign:"center", 
            }}>
                        <div><img src={phone} alt="tickIcon"  /> </div>
                        <a className="ph" onClick={handleClick} href={tfn1 === null ? "tel:+18335256234":getTfn(tfn1)}>{tfn1 === null? getTfnFormat("1-833-525-6234") :getTfnFormat(tfn1)} </a>
                    </SecondFlexother>
                <BoldText>Restrictions Apply</BoldText>
                <LiteText>Spectrum Internet required.</LiteText>
            </DetailWrapper>
        </MainWrapper>
        </div>
     
        </Carousel.Item>
        <Carousel.Item interval={2000}>

        {/* <img src={silder1} className='carousel-imgg' alt="img"/> */}
        {isDesktop ? (
              <div className='carousel-imggg'> 
      <div
        className="row mob"
        style={{ alignItems: "center", marginLeft:"-15px" }}
      >
        <div className="col-md-6 col-xl-6">
          <div style={{display:"flex", justifyContent:"center"}}>
       <img  src={Banner} alt="img" className="image_banner" />
        </div>
        </div>
        <div className="col-md-6"> 
          <div className="container section_space">
            <h1 className="heading_section">
              Bundle Frontier Internet service with home phone
            </h1>
            <p className="paragraph_section">
              Add home phone to Frontier Internet plans to build out your home
              business hub, stay tapped into the citywide emergency grid, or
              revisit the pleasure of a retro telephone set. Then pivot to the
              futuristic speeds of Frontier Fiber. It’s all available on one
              bill when you bundle Frontier Internet and home phone today.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className="planButton">Shop Frontier Bundles</button>
            </div>
           </div>
         </div> 
      </div>
      </div>
       ):(
        <div className="container">
        <div
        className="row mob"
        style={{ alignItems: "center", height:"650px", marginLeft:"-15px" }}
      >
        <div className="col-md-6 col-xl-6">
        <LazyLoadImage  effect="blur" src={Banner} alt="img" className="image_banner" />
        </div>
        <div className="col-md-6"> 
          <div className="container">
            <h1 className="heading_section mt-4">
              Bundle Frontier Internet service with home phone
            </h1>
            <p className="paragraph_section">
              Add home phone to Frontier Internet plans to build out your home
              business hub, stay tapped into the citywide emergency grid, or
              revisit the pleasure of a retro telephone set. Then pivot to the
              futuristic speeds of Frontier Fiber. It’s all available on one
              bill when you bundle Frontier Internet and home phone today.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className="planButton">Shop Frontier Bundles</button>
            </div>
           </div>
         </div> 
      </div>
      </div>
       )}
      
   
        </Carousel.Item>
      </Carousel>
   
     </>
    )
}

export default Section3;
