
import { withRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./style.css";
import section1_img from "../../assets/images/cro/section1.webp";
import { IoCall } from "react-icons/io5";
import { IoAdd } from "react-icons/io5";
const Section1 = () => {

  const [tfn, setTfn] = useState(null);
  const handleClick = () => {
    // Check if the gtag function is defined
    if (typeof window.gtag === 'function') {
      const phoneNumber = tfn || "1-833-923-0269"; 
   
      window.gtag('event', 'conversion', {
        'send_to': 'AW-606595014/9F__CNHTn94DEMbPn6EC',
        //  'event_callback': 'callback',
        'phone_number': phoneNumber,
      });
      
    } else {
      console.error('Google Analytics gtag function is not defined.');
    }
  
  };
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let tfnFromUrl = urlParams.get("tfn");

    if (tfnFromUrl) {
      setTfn(tfnFromUrl);
    }
  }, []);

  const setUrl = (url) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set("tfn", url);
    let newurl =
      window.location.protocol +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString();
    window.history.pushState({ path: newurl }, "", newurl);
    window.scrollTo(0, 0);
    setTfn(url);
  };

  const getTfn = (tfn) => {
    let temp = "";
    for (let i = 0; i < tfn.length; i++) {
      if (tfn[i] !== "-") {
        temp = temp + tfn[i];
      }
    }
    return "tel:+" + temp;
  };

  const getTfnFormat = (tfn) => {
    let _tfn = "";
    tfn = tfn.split("-");
    _tfn = "1" + "-" + tfn[1] + "-" + tfn[2] + "-" + tfn[3];
    return _tfn;
  };
  const numberToShow = tfn || "1-833-923-0269";
    return (
      <>
      
        <div className="sec1_con">
          <div className="container">
            <div className="space_divOne">
            <div className="row ">
              <div className="col-xl-6 col-sm-12 col-md-12 sec1_col1">
                <h1 className="sec1_h1">
                  FRONTIER INTERNET<sup>®</sup>
                </h1>
                <span className="sec1_span1">Fiber 1 Gig</span>
                <div>
                  <div className="sec1_price">
                    <h2>$59.99</h2>
                  </div>
                  <div className="sec1_content">
                    <p>
                      per month for 12 months w/ Auto Pay & Paperless Bill. Max
                      wired speed 1000/1000. Wi-Fi, actual & average speeds
                      vary. Early termination
                      <sup style={{ fontSize: "smaller", lineHeight: "1rem" }}>
                        ††
                      </sup>{" "}
                      & one-time charges apply. In select areas where available.
                    </p>
                  </div>
                </div>
                <div className="sec1_ulDiv mt-3">
                  <ul className="sec1_ul">
                    <li>
                      <IoAdd style={{ marginRight: "5px" }} />
                      Amazon eero Wi-Fi Pro 6 router included**
                    </li>
                    <li>
                      <IoAdd style={{ marginRight: "5px" }} />
                      Expert installation included ($100 value)
                    </li>
                    <li>
                      <IoAdd style={{ marginRight: "5px" }} />
                      Extreme bandwidth for smart homes with dozens of devices
                    </li>
                  </ul>
                </div>
                
                <div className="sec1_btn">
                <div className="phonee_divv my-2">
                <a className="sec2_b" onClick={handleClick}  href={tfn === null ? "tel:+18339230269": getTfn(tfn)}><span style={{display:"flex", alignItems:"center", gap:"0.5rem"}}><IoCall size={20} />CALL {tfn === null? getTfnFormat("1-833-923-0269") : getTfnFormat(tfn)}</span></a>
                </div>
                {/* <button className="sec1_b px-4 py-3" onClick={handleButtonClick}><IoCall />  <span style={{padding:"5px"}}>Call Now {numberToShow}</span></button> */}
                  {/* <button className="sec1_b px-4 py-3">
                    <IoCall />
                    <span style={{padding:"10px"}}>CALL NOW 1-833-779-2132</span>
                  </button> */}
                </div>
              </div>
              <div className="col-xl-6 offset-md-12 sec1_imgCol">
                
                <img className="sec1_col2Img" src={section1_img} />
              </div>
            </div>
            </div>
          </div>
        </div>
      </>
    );
  }


export default withRouter(Section1);
