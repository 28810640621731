import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './hocs/Layout';
import './App.css'
import '../src/css/style1.css'
// import '../src/css/style2.css'
// import '../src/css/style3.css'
import { routes } from "./Routes/Routes";
//fonts
import "./fonts/SpectrumSans_Bd.ttf"
import "./fonts/SpectrumSans_BdIt.ttf"
import "./fonts/SpectrumSans_Bk.ttf"
import "./fonts/SpectrumSans_BkIt.ttf"
import "./fonts/SpectrumSans_It.ttf"
import "./fonts/SpectrumSans_Lt.ttf"
import "./fonts/SpectrumSans_LtIt.ttf"
import "./fonts/SpectrumSans_Md.ttf"
import "./fonts/SpectrumSans_MdIt.ttf"
import "./fonts/SpectrumSans_Rg.ttf"
import "./fonts/SpectrumSans_XBd.ttf"
import "./fonts/SpectrumSans_XBdIt.ttf"
class App extends Component {


  componentDidMount() {

    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-606595014";
    document.body.appendChild(script);

  }
  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Switch>
            {
              routes.map((obj) => {
                return (
                  <Route exact path={obj.path} component={obj.component} />
                )
              })
            }
            {/* <Redirect from="/privacy" to="/privacy" />  */}
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default (App);
