import styled from "styled-components";

export const MainWrapper = styled.div`
    width: 100%;
    padding: 0% 4%;
    display: flex;
    flex-direction: column;
    gap: 20px;

     @media (min-width: 576px) and (max-width: 768px)  {
        padding: 0% 4% 3% 4%;
            flex-direction: row;
    }
                   @media (min-width: 769px) {
                     padding: 0% 4% 3% 4%;
            flex-direction: row;
                   }
    `
export const ImgWrapper = styled.div`
    width: 100%;
margin:15px auto;

@media (min-width: 576px) and (max-width: 768px)  {
    width: 50%;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}
               @media (min-width: 769px) {
                width: 50%;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
               }

               img{
                width: 70%;
         
                @media (min-width: 576px) and (max-width: 768px)  {
                    width:80%;
                }
                @media (min-width: 769px) {
                width:100%;
                       display: flex;
    justify-content: center;
   
                }
               }
`
export const ImgContainer = styled.div`
height: 150px;
border-radius: 20px;
background-image: linear-gradient(0deg, #003057 0%, #00629b 100%);
border: 5px solid black;
box-shadow: 0 5px 8px -2px gray;
padding: 6%;
display: flex;
    justify-content: center;
    align-items: center;

  @media (min-width: 769px) {
                width: 75%;
                }
`
export const TopFlex = styled.div`
display: flex;
>div:first-child{
           font-size: 30px;
            color: blue;
                    margin-bottom: 0!important;
 transform: rotate(-90deg);
  transform-origin: center ;
  text-align: end;
  padding-top : 60px;
  background: linear-gradient(to right, #43c5fb 0%, #98d2eb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

`
export const InerFlex = styled.div`
display: flex;
flex-direction: column;
div{
    display: flex;
     >p:nth-of-type(1){
      font-size: 52px;
            color: white;
        margin-bottom: 0!important;
    }
    >p:nth-of-type(2){
        font-size: 30px;
            color: blue;
        margin-bottom: 0!important;
    }
}
p:last-child{
    font-size: 40px;
    font-weight: 800;
            color: white;
        margin-bottom: 0!important;

}
`
export const DetailWrapper = styled.div`

@media (min-width: 576px) and (max-width: 768px)  {
    width: 50%;
    order: 1;
    padding-left:60px;
}
               @media (min-width: 769px) {
                   width: 50%;
                    margin-left:0px;
    order: 1;
               }
`
export const GreyText = styled.p`
font-size: 16px;
line-height: 22px;
color: #333333;
font-weight: 300;
font-family: "SpectrumSans Light";
`

export const SmallTitle = styled.p`
font-size: 18px;
color: #333333;
margin-bottom: 0 !important;
font-family: "SpectrumSans Light";
`
export const BoldText = styled.p`
font-size: 12px;
line-height: 18px;
color: #767676;
font-weight: 500;
margin-top: 4%;
margin-bottom: 0!important;
`
export const LiteText = styled.p`
font-size: 12px;
line-height: 18px;
color: #767676;
/* font-weight: 500; */
margin-bottom: 30px;
`








