import styled from "styled-components";
export const MainWrapper = styled.div`
height: 1370px;
width: 100%;
    padding: 10% 4%;
        background-color: #fafafa;
 @media (min-width: 576px) and (max-width: 768px)  {
 height: auto;
 }
               @media (min-width: 769px) {
                padding: 5% 4%;
                height: auto;

               }
`
export const MainTitle = styled.p`
width: 80%;
font-size: 36px;
line-height: 43px;
color: #333333;
font-weight: 500;
text-align: center;
margin: 0 auto 0.5rem auto;
font-family: "SpectrumSans Medium";
`
export const Description = styled.p`
font-size: 21px;
line-height: 27px;
color: #333333;
font-weight: 300;
text-align: center;
font-family: "SpectrumSans Light";
 @media (min-width: 576px) and (max-width: 768px)  {
        width: 80%;
        text-align: center;
        margin: 0 auto;
}
              @media (min-width: 769px) {
                 width: 80%;
        text-align: center;
        margin: 0 auto;
              }

`
export const PacagesFlex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
     @media (min-width: 576px) and (max-width: 768px)  {
        margin-top: 4%;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        /* justify-content: space-between; */
        justify-content: center;
        
}
              @media (min-width: 769px) {
                     margin-top: 4%;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        /* justify-content: space-between; */
        justify-content: center;
              }
`

