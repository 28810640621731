import styled from "styled-components";
export const MainWrapper = styled.div`
width: 100%;
// height: 348px;
background-color: #ffffff;
border: 1px solid #cccccc;
display: flex;
flex-direction: column;
 @media (min-width: 576px) and (max-width: 768px)  {
    width: 48%;
    // height: 352px;
 }
              @media (min-width: 769px) {
                width: 32%;
    // height: 375px;
              }
`

export const FirstWrapperDiv = styled.div`
width: 100%;
height: 196px;
background-color: #003057;
padding: 4% 0;
display: flex;
flex-direction:column;
align-items: center;
position: relative;
`
export const Title1 = styled.p`
font-size: 18px;
letter-spacing: 2px;
line-height: 20px;
text-transform: uppercase;
color: #1dbafb;
text-align: center;
margin-bottom: 0 !important;
font-family: "SpectrumSans Regular";
`
export const SubTitle = styled.p`
font-size: 18px;
letter-spacing: 2px;
line-height: 20px;
text-transform: uppercase;
color: white;
text-align: center;
margin-bottom: 0 !important;
font-family: "SpectrumSans Regular";
`

export const ImgWrapper = styled.div`
width: 60%;
img{
    width: 100%;
}
`
export const Button = styled.button`
position: absolute;
bottom: -20px;
font-size: 19px;
text-transform: uppercase;
color: #ffffff;
text-align: center;
width: 170px;
height: 40px;
border-radius: 5px;
background-color: #0099d8;
border: none;
outline: none;
font-family: "SpectrumSans Bold";
`
export const SecondWrapperDiv = styled.div`
    padding: 12% 2% 4% 2%; 
    display: flex;
    justify-content: center;
    height: 100%;
        
     

`
export const IconFlex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #cccc;
    width: 100%;
    height: 100%;
    padding: 2% ;
    gap: 10px;
    div{
        width: 29px;
    height: 29px;
img{
    width: 100%;
    /* height: 100%; */
}   

 
}
p{
    font-size: 16px;
line-height: 22px;
color: #333333;
font-weight: 300;
text-align: center;
margin-bottom:  0!important;
font-family: "SpectrumSans Light";

 }
&:nth-of-type(3){
    border-right: none;
}
&:nth-of-type(3) {  
    height:29px;
    img{
        height: 100%;
        object-fit: contain;
    }
}

`