import styled from "styled-components";

export const HeaderWrapper = styled.div`
    width: 100%;
    padding: 3% 5%;
    height: 69px;
    box-sizing: border-box;
     @media (min-width: 576px) and (max-width: 768px)  {
            height: 70px;
                padding: 0 5%;
  }
   @media (min-width: 769px) {
     height: 100px;
                padding: 0 5%;
  }
`
export const LogoWrapper = styled.div`
    width: 142px;
   @media (min-width: 576px) and (max-width: 768px)  {
             width: 100%;
  }
   @media (min-width: 769px) {
     width: 40%;
  }     
    img{
        width: 100%;
        cursor: pointer;
    }
`
export const ContactDetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    float: right;
    margin-top: 15px;
text-align: right;
     @media (min-width: 576px) and (max-width: 768px)  {
        padding: 4% 0;
            width: fit-content;
justify-self: flex-end;
    }
     @media (min-width: 769px) {
     padding: 4% 0;
            width: fit-content;
justify-self: flex-end;
  }

    p:nth-of-type(1){
       font-size: 9px;
        text-transform: uppercase;
        color: #001b33;
        font-family: "SpectrumSans Medium";
        margin-bottom: 0!important;
        line-height: 0;
        text-align: left;
        
                   @media (min-width: 576px) and (max-width: 768px)  {
                        text-align: start;
                   }
                    @media (min-width: 769px) {
                             text-align: start;

  }
                   
    }

a{
font-size: 20px;
-webkit-letter-spacing: 1px;
-moz-letter-spacing: 1px;
-ms-letter-spacing: 1px;
letter-spacing: 1px;
color: #001b33;
font-weight: bold;
text-align: right;
text-decoration: none;

@media (min-width: 576px) and (max-width: 768px)  {
font-size: 26px;
letter-spacing: 1px;
color: #001b33;
font-weight: bold;
text-align: right;
        
  }

@media (min-width: 769px) {
font-size: 26px;
letter-spacing: 1px;
color: #001b33;
font-weight: bold;
text-align: right;
  }
    }

   



    
`

