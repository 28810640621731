import styled from "styled-components";
// import bg from "../../assets/images/mobile/bg.png"
export const MainContainer = styled.div`
padding: 5% 4%;`
export const MainWrapper = styled.div`
width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #8080803d;
    gap: 22px;
     @media (min-width: 576px) and (max-width: 768px)  {
        display: flex;
        flex-direction: row;
    }
             @media (min-width: 769px) {
                display: flex;
        flex-direction: row;
             }
    `
export const ImgWrapper = styled.div`
    width :  100%;
     @media (min-width: 576px) and (max-width: 768px)  {
       width: 50%;
       display: flex;
       justify-content: center;
       align-items: center;
    }
             @media (min-width: 769px) {
                width: 50%;
       display: flex;
       justify-content: center;
       align-items: center;
       height: 374px;

             }
img{
    width: 100%;
                 @media (min-width: 769px) {
                height : 100%;
                object-fit: contain;
                }
}
`
export const ContentWrapper = styled.div`
/* margin-top: 4%; */
@media (min-width: 576px) and (max-width: 768px)  {
       width: 50%;
    }
         @media (min-width: 769px) {
                   width: 50%;
         }
`
export const BlueTitle = styled.p`
    font-size: 18px;
letter-spacing: 2px;
line-height: 20px;
text-transform: uppercase;
color: #00629b;
font-family: "SpectrumSans Regular";
margin-bottom: 0%;
`
export const Title1 = styled.p`
font-size: 36px;
line-height: 43px;
color: #333333;
font-weight: 500;
font-family: "SpectrumSans Medium"; 
`
export const MainFlex = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
`
export const InnerFlex = styled.div`
display:flex;
gap: 11px;
div{
    width: 20px;
height: 20px;
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
    img{
            width: 100%;
    }
}
p{
    font-size: 16px;
line-height: 22px;
color: #333333;
font-weight: 300;
}

`
export const SecondFlex = styled.div`
display:flex;
// gap: 11px;
align-items: center;
justify-content:center;
padding: 0 0 4% 0 ;
// margin-top: 10px;
div{
    width: 20px;
height: 20px;
display: flex;
justify-content: center;
align-items: center;
margin-right:2%;

    img{
            width: 100%;
            
    }

}
a{
    font-size: 22px;
line-height: 38px;
color: #333333;
font-weight: 500;
margin-bottom: -6px;
font-family: "SpectrumSans Medium";
text-decoration: none;
}

`
export const SecondFlexother = styled.div`
display:flex;
// gap: 11px;
align-items: center;
justify-content:center;
padding: 0 0 4% 0 ;
// margin-top: 10px;
div{
    width: 20px;
height: 20px;
display: flex;
justify-content: center;
align-items: center;
margin-right:2%;

    img{
            width: 100%;
            
    }

}
a{
    font-size: 22px;
line-height: 38px;
color: white;
font-weight: 500;
margin-bottom: -6px;
font-family: "SpectrumSans Medium";
text-decoration: none;
}

`

export const LiteText = styled.p`
font-size: 12px;
line-height: 18px;
color: #767676;
margin: 20px 0;
font-family: "SpectrumSans Medium";
`