import React from 'react'
import "./style.css"
import logo1 from "../../assets/images/logos/ELReseller_Logo_Primary_Pos_rgb.jpg";
import logo2 from "../../assets/images/logos/Frontier_AuthSalesAgent_Secondary_Small_RGB_Red.png";
import logo3 from "../../assets/images/logos/KineticByWindstreamAuthAgentLogoHorz4c.jpg";
import logo4 from "../../assets/images/logos/Opt_Auth_Reseller_Logo_Vert_POS_RGB.png";
import logo5 from "../../assets/images/logos/Spectrum_Auth_Retailer_Vert_RGB.jpg";
import logo6 from "../../assets/images/logos/Viasat_AuthorizedRetailer.png";
import logo7 from "../../assets/images/logos/directv_DealerBdg_Hz_rgb_blk.png";
import Marquee from "react-fast-marquee"
const marquee = () => {
  return (
   <div className='marquee_div_box'>
    <Marquee speed={100}>
        <img src={logo1} alt="Earthlink Authorized Reseller" className='logo_div'/>
        <img src={logo2} alt="Frontier Authorized Sales Agent" className='logo_div' />
        <img src={logo3} alt="Kinetic By Wind Stream Authorized Agent" className='logo_div'/>
        <img src={logo4} alt="Optimum Authorized Reseller" className='logo_div' />
        <img src={logo5} alt="Spectrum Authorized Retailer" className='logo_div' />
        <img src={logo6} alt="Viasat Authorized Retailer" className='logo_div'/>
        <img src={logo7} alt="Directv Dealer" className='logo_div' />
    </Marquee>
   </div>
  )
}

export default marquee