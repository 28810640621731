import React, { useEffect, useState } from 'react'
import { MainWrapper, ImgWrapper, Title1, Ptag, Title2, MainFlex, InnerFlex, DetailWrapper, Textwrapper } from "./Section1.styled"
import bannerImg from "../../assets/images/mobile/device-lockup.webp"
import silder0 from "../../assets/images/cro/0.jpg"
import silder1 from "../../assets/images/cro/4.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Carousel from 'react-bootstrap/Carousel';
import Section5 from "../Section5/Section5"
const Section1 = () => {
    const [isDesktop, setIsDesktop] = useState(
        window.innerWidth > 768 ? true : false
    );
    const [isTab, setIsTab] = useState(
        (window.innerWidth >= 576 && window.innerWidth <= 768) ? true : false
    );
    const updateDimensions = () => {
        setIsDesktop(window.innerWidth > 768 ? true : false);
        setIsTab((window.innerWidth >= 576 && window.innerWidth <= 768) ? true : false);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [isDesktop]);
    return (
       
        <Carousel>
        <Carousel.Item interval={2000}>
        <img src={silder0} className='carousel-img mb-5' alt="slider-img" alt="slider-img"/> 
        {/* <MainWrapper>
            <ImgWrapper>
              <LazyLoadImage  effect="blur"  src={!isTab && !isDesktop ? bannerImg : bannerImg} alt="bannerImage" />
            </ImgWrapper>
            <Textwrapper>
                <Title1>Save With Spectrum One</Title1>
                <Ptag>Experience seamless connectivity across all your devices. With Spectrum One, get Spectrum Internet®, Advanced WiFi and an  Unlimited Mobile line all for one incredible price</Ptag>
                <Title2>Internet + Advanced WiFi + Mobile</Title2>
                <MainFlex>
                    <p>$</p>
                    <p>49</p>
                    <InnerFlex>
                        <p><span style={{ fontFamily: 'SpectrumSans Bold' }}>99</span><span style={{ fontFamily: 'SpectrumSans Light', fontSize: '15px' }}>/mo</span></p>
                        <p><span style={{ fontFamily: 'SpectrumSans Regular' }}>for 12 mos</span></p>
                        <p><span style={{ fontFamily: 'SpectrumSans Regular' }}>when bundled</span></p>
                    </InnerFlex>
                </MainFlex>
            </Textwrapper>
        </MainWrapper>  */}
        </Carousel.Item>
        <Carousel.Item interval={2000}>
            <div className='carousel-img mb-5'>
        <Section5/>
        </div>
        {/* <img src={silder1} className='carousel-img' /> */}
        </Carousel.Item>
      </Carousel>
   
        // <MainWrapper>
        //     <ImgWrapper>
        //       <LazyLoadImage  effect="blur"  src={!isTab && !isDesktop ? bannerImg : bannerImg} alt="bannerImage" />
        //     </ImgWrapper>
        //     <Textwrapper>
        //         <Title1>Save With Spectrum One</Title1>
        //         <Ptag>Experience seamless connectivity across all your devices. With Spectrum One, get Spectrum Internet®, Advanced WiFi and an  Unlimited Mobile line all for one incredible price</Ptag>
        //         <Title2>Internet + Advanced WiFi + Mobile</Title2>
        //         <MainFlex>
        //             <p>$</p>
        //             <p>49</p>
        //             <InnerFlex>
        //                 <p><span style={{ fontFamily: 'SpectrumSans Bold' }}>99</span><span style={{ fontFamily: 'SpectrumSans Light', fontSize: '15px' }}>/mo</span></p>
        //                 <p><span style={{ fontFamily: 'SpectrumSans Regular' }}>for 12 mos</span></p>
        //                 <p><span style={{ fontFamily: 'SpectrumSans Regular' }}>when bundled</span></p>
        //             </InnerFlex>
        //         </MainFlex>
        //     </Textwrapper>
        // </MainWrapper>
    )
}

export default Section1;
