import React, { useState, useEffect } from "react";
import Section1 from '../../components/Section1/Section1';
import Section2 from '../../components/Section2/Section2';
import Section4 from '../../components/Section4/Section4';
import Section3 from '../../components/Secton3/Section3';
import Section6 from "../../components/Section6/Section6";
import { MainContainer } from "../../hocs/Layout.styled";
import MarqueeSection from "../../components/Marquee/marquee"
const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 900;

      if (window.scrollY > scrollThreshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove event listener when component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); 

  const [tfn, setTfn] = useState(null);
  const handleClick = () => {
    // Check if the gtag function is defined
    if (typeof window.gtag === 'function') {
      const phoneNumber = tfn || "1-833-923-0269"; 
   
      window.gtag('event', 'conversion', {
        'send_to': 'AW-606595014/9F__CNHTn94DEMbPn6EC',
        //  'event_callback': 'callback',
        'phone_number': phoneNumber,
      });
      
    } else {
      console.error('Google Analytics gtag function is not defined.');
    }
  
  };
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let tfnFromUrl = urlParams.get("tfn");

    if (tfnFromUrl) {
      setTfn(tfnFromUrl);
    }
  }, []);

  const setUrl = (url) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set("tfn", url);
    let newurl =
      window.location.protocol +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString();
    window.history.pushState({ path: newurl }, "", newurl);
    window.scrollTo(0, 0);
    setTfn(url);
  };

  const getTfn = (tfn) => {
    let temp = "";
    for (let i = 0; i < tfn.length; i++) {
      if (tfn[i] !== "-") {
        temp = temp + tfn[i];
      }
    }
    return "tel:+" + temp;
  };

  const getTfnFormat = (tfn) => {
    let _tfn = "";
    tfn = tfn.split("-");
    _tfn = "1" + "-" + tfn[1] + "-" + tfn[2] + "-" + tfn[3];
    return _tfn;
  };
  return (
    <>
   
      <Section1 />
      <MarqueeSection/>
      <Section2 />
     
      <MainContainer>
      <Section3 />
      <Section4 />
      <div
    className="elementor-element elementor-element-63f5d3c elementor-widget elementor-widget-text-editor mt-4"
    data-id="63f5d3c"
    data-element_type="widget"
    data-widget_type="text-editor.default"
  >
    <div className="elementor-widget-container">
      <p>
        {" "}
        Internet + TV Select + Voice: Limited time offer;
        subject to change; valid to qualified residential
        customers who have not subscribed to any services
        within the previous 30 days and who have no
        outstanding obligation to Charter. TV Select
        promotion price is $49.99/mo; standard rates apply
        after yr. 1. Internet promotion price is $49.99/mo;
        standard rates apply after yr. 1. Voice: Price is
        $14.99/mo when bundled. Taxes, fees and surcharges
        (broadcast surcharge up to $21.00/mo) extra and
        subject to change during and after the promotional
        period; installation/network activation, equipment and
        additional services are extra. General Terms:
        INTERNET: Speed based on wired connection and may vary
        by address. Wireless speeds may vary. TV: TV equipment
        required; charges may apply. Channel and HD
        programming availability based on level of service.
        Account credentials may be required to stream some TV
        content online. HOME PHONE: Unlimited calling includes
        calls within the U.S., Canada, Mexico, Puerto Rico,
        Guam, the Virgin Islands and more. Services subject to
        all applicable service terms and conditions, subject
        to change. Services not available in all areas.
        Restrictions apply. Enter your address to determine
        availability. ©2022 Charter Communications.
      </p>{" "}
    </div>
  </div>
  </MainContainer>
  {isVisible && (
      <div className="section2">
        <Section6 tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick} />
      </div>
    )}
    </>
  );
};

export default Home;